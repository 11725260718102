<!--
 * @Author: lbh
 * @Date: 2024-03-05 09:56:29
 * @LastEditors: lbh
 * @LastEditTime: 2024-09-10 15:05:11
 * @Description: file content
-->
<template>
  <div
    class="questions-and-answers-box"
    :style="{
      '--top': configs.showTopImg ? '' : '0',
      '--height': configs.showTopImg ? '0' : 'var(--font50)',
    }"
    @click="clickItem('box')"
  >
    <!-- 頂部波浪 -->
    <div class="top-radius">
      <img
        v-if="configs.showTopImg"
        src="https://hk-ricepon.oss-cn-hongkong.aliyuncs.com//images/webAdmin//1709627999552png"
      />
    </div>
    <!-- 主體 -->
    <div class="qaa-body">
      <!-- 普通文字 -->
      <div
        v-for="(item, index) in configs.items"
        :key="index"
        class="qaa-cell"
        @click.stop="clickItem('cell', index)"
      >
        <div
          class="qaa-cell-head"
          :style="{
            '--qBgColor': item.question.bgColor,
            '--qColor': item.question.color,
            '--qFontSize': isPc
              ? item.question.fontSize
              : item.question.fontSizeByPhone,
          }"
        >
          <div
            class="qaa-cell-head_title"
            v-text="
              $util.rex.getHtml(
                item.question[`${nowL}text`] || item.question.text
              )
            "
          ></div>
        </div>
        <!-- 文本 -->
        <div
          v-if="item.answer.type == 1"
          class="qaa-cell-content"
          :style="{
            '--fontSize': isPc
              ? item.answer.text.fontSize
              : item.answer.text.fontSizeByPhone,
          }"
          v-html="
            $util.rex.getHtml(
              item.answer.text[`${nowL}content`] || item.answer.text.content
            )
          "
        ></div>
        <!--  圖片 -->
        <div v-else-if="item.answer.type == 2" class="qaa-cell-content">
          <img :src="item.answer.img.src" alt="" />
        </div>
        <!-- 表格 - 普通 -->
        <div
          v-else-if="item.answer.type == 3"
          class="qaa-cell-content"
          :class="item.answer.table.type == 1 ? 'default' : 'solid'"
        >
          <table border="0" cellspacing="0" cellpadding="0">
            <template v-for="trItem in item.answer.table.trs">
              <tr
                v-if="trItem.type == 'th'"
                :style="{ '--thTrColor': trItem.bgColor }"
              >
                <th
                  v-for="(thItem, thIndex) in trItem.child"
                  :key="thIndex"
                  :style="{
                    '--thWidth': thItem.width,
                    '--thBgColor': thItem.bgColor,
                    '--thFontSize': thItem.fontSize,
                    '--thColor': thItem.color,
                  }"
                >
                  <span
                    v-html="
                      $util.rex.getHtml(thItem[`${nowL}text`] || thItem.text)
                    "
                  ></span>
                </th>
              </tr>
              <tr v-else :style="{ '--thTrColor': trItem.bgColor }">
                <td
                  v-for="(thItem, thIndex) in trItem.child"
                  :key="thIndex"
                  :style="{
                    '--thWidth': thItem.width,
                    '--thBgColor': thItem.bgColor,
                    '--thFontSize': thItem.fontSize,
                    '--thColor': thItem.color,
                    '--thTextAlign': thItem.textAlign,
                  }"
                  :rowspan="thItem.rowspan"
                  :class="thItem.textAlign"
                >
                  <div
                    v-html="
                      $util.rex.getHtml(thItem[`${nowL}text`] || thItem.text)
                    "
                  ></div>
                </td>
              </tr>
            </template>
          </table>
        </div>
      </div>
      <!-- 結尾的 whatsapp -->
      <div
        @click.stop="clickItem('btn', configs.btn.go)"
        class="bottom-btn"
        :style="{
          '--fontSize': isPc
            ? configs.btn.fontSize
            : configs.btn.fontSizeByPhone,
          '--bgColor': configs.btn.bgColor,
          '--color': configs.btn.color,
        }"
      >
        <img v-if="configs.btn.icon" :src="configs.btn.icon" />
        <span
          v-html="
            $util.rex.getHtml(configs.btn[`${nowL}text`] || configs.btn.text)
          "
        ></span>
      </div>
      <!-- 懸浮 的 whatsapp -->
      <div
        v-if="configs.whatsapp.icon"
        class="whatsapp"
        @click="$util.route.go(configs.whatsapp.go)"
      >
        <img :src="configs.whatsapp.icon" />
      </div>

      <!-- 其他區域 -->
      <!-- 第一個右邊 圓 -->
      <div class="right-one-radius"></div>
      <!-- 左邊 第一個 圓 -->
      <div class="left-one-radius"></div>
      <!-- 左邊第一個三角形 -->
      <div class="left-one-si"></div>
      <div class="right-one-si"></div>
    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';

export default {
  name: 'questions-and-answers',
  mixins: [langMixin],
  props: {
    configs: {
      default() {
        return {
          src: '',
          title: '',
        };
      },
    },
    Xindex: {
      default() {
        return '';
      },
    },
  },
  computed: {
    isPc() {
      return window.innerWidth > 768;
    },
  },
  created() {
    // < !--Event snippet for 提交潜在客户表单 conversion page-- >
    let s = document.createElement('script');
    s.innerHTML = `gtag('event', 'conversion', {'send_to': 'AW-11477749821/dC3BCLW325kZEL2gguEq'});`;
    document.head.append(s);
  },
  methods: {
    clickItem(e, x) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x);
      } else {
        if (e == 'btn') {
          this.$util.route.go(x);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
@keyframes upToDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0);
  }
}

@media screen and (max-width: 768px) {
  .questions-and-answers-box {
    .qaa-cell {
      width: calc(100vw - var(--font40)) !important;
      .qaa-cell-head {
        width: 100%;
        &::before,
        &::after {
          display: none;
        }
        .qaa-cell-head_title {
          width: 100%;
          padding: var(--font10) var(--font20) !important;
        }
      }
    }
    tr {
      td {
        &.left {
          div {
            padding-left: var(--font14) !important;
          }
        }
      }
    }
    .whatsapp {
      left: var(--font20) !important;
      bottom: var(--font256) !important;
    }
    .bottom-btn {
      padding: var(--font12) var(--font16) !important;
      height: auto !important;
    }
  }
}

.questions-and-answers-box {
  margin-top: var(--top, calc(var(--font80) * -1));
  position: relative;
  z-index: 10;
  overflow: hidden;
  --bg: rgba(242, 248, 255, 1);
  --othBg: rgb(232, 242, 252);
  .top-radius {
    width: 100%;

    img {
      width: 100%;
      vertical-align: middle;
    }
  }
  .qaa-body {
    background-color: var(--bg);
    padding-top: var(--height);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .qaa-cell {
      width: 56vw;
      margin-bottom: var(--font70);
      position: relative;
      z-index: 5;
      .qaa-cell-head {
        display: flex;
        position: relative;
        .qaa-cell-head_title {
          background-color: var(--qBgColor, rgba(207, 230, 255, 1));
          color: var(--qColor, rgba(0, 76, 158, 1));
          font-size: var(--qFontSize, var(--font30));
          padding: var(--font14) var(--font39);
          border-radius: var(--font42);
          z-index: 1;
        }
        &::before {
          position: absolute;
          content: '';
          height: var(--font2);
          background-color: var(--qBgColor, rgba(207, 230, 255, 1));
          left: var(--font52);
          right: var(--font52);
          top: 50%;
          transform: translateY(-50%);
        }
        &::after {
          position: absolute;
          content: '';
          border-width: var(--font8);
          border-color: var(--qBgColor, rgba(207, 230, 255, 1));
          border-style: solid;
          right: 0;
          top: 50%;
          transform: translateY(-50%) rotateZ(45deg);
        }
      }
      .qaa-cell-content {
        margin-top: var(--font30);
        font-size: var(--fontSize, --font20);
        line-height: var(--font40);
        &.solid {
          table {
            tr {
              th {
                border-left: var(--font2) solid rgba(215, 232, 252, 1);
                border-bottom: var(--font2) solid #fff;
              }
              td {
                border-left: var(--font2) solid rgba(215, 232, 252, 1);
                border-bottom: var(--font2) solid rgba(215, 232, 252, 1);
                border-top: none;
              }
              &:last-of-type {
                td {
                  border-bottom: none;
                }
              }
            }
          }
        }
        img {
          width: 100%;
          border-radius: var(--font8);
        }
        table {
          border: var(--font2) solid rgba(167, 203, 242, 1);
          border-radius: var(--font8);
          overflow: hidden;
          width: 100%;
          tr {
            height: var(--font70);
            th {
              width: var(--thWidth);
              height: var(--font70);
              border-left: var(--font1) solid #fff;
              text-align: center;
              background-color: var(
                --thTrColor,
                var(--thBgColor, rgba(207, 230, 255, 1))
              );
              color: var(--thColor, #333);
              font-size: var(--thFontSize);
              &:first-of-type {
                border-left: none;
              }
            }
            td {
              border-left: var(--font1) solid #fff;
              border-top: var(--font1) solid #fff;
              text-align: center;
              background-color: var(
                --thTrColor,
                var(--thBgColor, rgba(207, 230, 255, 1))
              );
              font-size: var(--thFontSize);
              color: var(--thColor, #333);
              &.left {
                div {
                  padding-left: var(--font70);
                }
              }
              div {
                text-align: var(--thTextAlign, 'center');
              }
              &:first-of-type {
                border-left: none;
              }
            }
          }
        }
      }
    }
    .bottom-btn {
      height: var(--font80);
      padding: 0 var(--font106);
      background-color: var(--bgColor, rgba(59, 142, 237, 1));
      color: var(--color, #fff);
      border-radius: var(--font126);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: var(--fontSize, --font22);
      margin-bottom: var(--font96);
      cursor: pointer;
      position: initial;
      z-index: 5;
      &:hover {
        img {
          transform: scale(1.3) translateX(-20px);
        }
        span {
          transform: scale(1.3);
        }
      }
      img {
        width: var(--font40);
        height: var(--font40);
        margin-right: var(--font20);
        vertical-align: middle;
        transition: 0.5s;
      }
      span {
        transition: 0.5s;
      }
    }
    .whatsapp {
      position: fixed;
      background-color: var(--bgColor, rgb(50, 176, 74));
      left: var(--font106);
      bottom: var(--font96);
      z-index: 5000;
      width: var(--font60);
      height: var(--font60);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: 0.2s;
      box-shadow: 0 0 var(--font6) #c3c3c3;
      &:hover {
        box-shadow: 0 0 var(--font16) #c3c3c3;
      }
      img {
        width: var(--font35);
        vertical-align: middle;
      }
    }
  }
  .right-one-radius,
  .left-one-radius {
    position: absolute;
    width: var(--font420);
    height: var(--font420);
    background-color: var(--othBg);
    border-radius: 50%;
    top: var(--font80);
    right: calc(-1 * var(--font210));
    animation: upToDown 4s linear infinite;
  }
  .left-one-radius {
    top: calc(var(--font500) + var(--font500) + var(--font200));
    left: calc(-1 * var(--font210));
    right: auto !important;
  }
  .left-one-si,
  .right-one-si {
    width: 50vw;
    height: var(--font468);
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      176.53deg,
      rgba(232, 242, 252, 1) 0%,
      rgba(232, 242, 252, 0) 100%
    );
    -webkit-clip-path: polygon(0 0, 0% 100%, 100% 100%);
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
  }
  .right-one-si {
    left: auto;
    right: 0;
    background: linear-gradient(
      165.08deg,
      rgba(232, 242, 252, 1) 0%,
      rgba(232, 242, 252, 0) 100%
    );
    transform: rotate(180deg);
    -webkit-clip-path: polygon(100% 0, 0 0, 0 100%);
    clip-path: polygon(100% 0, 0 0, 0 100%);
  }
}
</style>
