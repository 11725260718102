var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"questions-and-answers-box",style:({
    '--top': _vm.configs.showTopImg ? '' : '0',
    '--height': _vm.configs.showTopImg ? '0' : 'var(--font50)',
  }),on:{"click":function($event){return _vm.clickItem('box')}}},[_c('div',{staticClass:"top-radius"},[(_vm.configs.showTopImg)?_c('img',{attrs:{"src":"https://hk-ricepon.oss-cn-hongkong.aliyuncs.com//images/webAdmin//1709627999552png"}}):_vm._e()]),_c('div',{staticClass:"qaa-body"},[_vm._l((_vm.configs.items),function(item,index){return _c('div',{key:index,staticClass:"qaa-cell",on:{"click":function($event){$event.stopPropagation();return _vm.clickItem('cell', index)}}},[_c('div',{staticClass:"qaa-cell-head",style:({
          '--qBgColor': item.question.bgColor,
          '--qColor': item.question.color,
          '--qFontSize': _vm.isPc
            ? item.question.fontSize
            : item.question.fontSizeByPhone,
        })},[_c('div',{staticClass:"qaa-cell-head_title",domProps:{"textContent":_vm._s(
            _vm.$util.rex.getHtml(
              item.question[`${_vm.nowL}text`] || item.question.text
            )
          )}})]),(item.answer.type == 1)?_c('div',{staticClass:"qaa-cell-content",style:({
          '--fontSize': _vm.isPc
            ? item.answer.text.fontSize
            : item.answer.text.fontSizeByPhone,
        }),domProps:{"innerHTML":_vm._s(
          _vm.$util.rex.getHtml(
            item.answer.text[`${_vm.nowL}content`] || item.answer.text.content
          )
        )}}):(item.answer.type == 2)?_c('div',{staticClass:"qaa-cell-content"},[_c('img',{attrs:{"src":item.answer.img.src,"alt":""}})]):(item.answer.type == 3)?_c('div',{staticClass:"qaa-cell-content",class:item.answer.table.type == 1 ? 'default' : 'solid'},[_c('table',{attrs:{"border":"0","cellspacing":"0","cellpadding":"0"}},[_vm._l((item.answer.table.trs),function(trItem){return [(trItem.type == 'th')?_c('tr',{style:({ '--thTrColor': trItem.bgColor })},_vm._l((trItem.child),function(thItem,thIndex){return _c('th',{key:thIndex,style:({
                  '--thWidth': thItem.width,
                  '--thBgColor': thItem.bgColor,
                  '--thFontSize': thItem.fontSize,
                  '--thColor': thItem.color,
                })},[_c('span',{domProps:{"innerHTML":_vm._s(
                    _vm.$util.rex.getHtml(thItem[`${_vm.nowL}text`] || thItem.text)
                  )}})])}),0):_c('tr',{style:({ '--thTrColor': trItem.bgColor })},_vm._l((trItem.child),function(thItem,thIndex){return _c('td',{key:thIndex,class:thItem.textAlign,style:({
                  '--thWidth': thItem.width,
                  '--thBgColor': thItem.bgColor,
                  '--thFontSize': thItem.fontSize,
                  '--thColor': thItem.color,
                  '--thTextAlign': thItem.textAlign,
                }),attrs:{"rowspan":thItem.rowspan}},[_c('div',{domProps:{"innerHTML":_vm._s(
                    _vm.$util.rex.getHtml(thItem[`${_vm.nowL}text`] || thItem.text)
                  )}})])}),0)]})],2)]):_vm._e()])}),_c('div',{staticClass:"bottom-btn",style:({
        '--fontSize': _vm.isPc
          ? _vm.configs.btn.fontSize
          : _vm.configs.btn.fontSizeByPhone,
        '--bgColor': _vm.configs.btn.bgColor,
        '--color': _vm.configs.btn.color,
      }),on:{"click":function($event){$event.stopPropagation();return _vm.clickItem('btn', _vm.configs.btn.go)}}},[(_vm.configs.btn.icon)?_c('img',{attrs:{"src":_vm.configs.btn.icon}}):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.$util.rex.getHtml(_vm.configs.btn[`${_vm.nowL}text`] || _vm.configs.btn.text)
        )}})]),(_vm.configs.whatsapp.icon)?_c('div',{staticClass:"whatsapp",on:{"click":function($event){return _vm.$util.route.go(_vm.configs.whatsapp.go)}}},[_c('img',{attrs:{"src":_vm.configs.whatsapp.icon}})]):_vm._e(),_c('div',{staticClass:"right-one-radius"}),_c('div',{staticClass:"left-one-radius"}),_c('div',{staticClass:"left-one-si"}),_c('div',{staticClass:"right-one-si"})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }